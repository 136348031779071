<template>
  <div>
    <div class="breadcrumb_header">
      <div class="breadcrumb ms-2">
        <h4 style="margin: 0; padding-left: 10px">NeXa</h4>
        <span class="divider"></span>
        <span>Draft a Legal Memo</span>
      </div>

      <div>
        <button class="open_sidebar me-2" @click="toggleSidebar">
          <span class="material-symbols-rounded icon"
            >keyboard_double_arrow_left</span
          >
          <p>Open Sidebar</p>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-1 mt-3">
        <div
          class="d-flex justify-content-start cursor-pointer back"
          @click="goBack"
        >
          <span class="material-symbols-rounded" style="margin: 0"
            >arrow_back</span
          >
          <span style="margin: 0 8px">Back</span>
        </div>
      </div>
      <div class="col-md-10 mt-5">
        <div class="left-container">
          <section class="tabs">
            <h5 class="tabs-title">Draft a Legal Memo</h5>
            <p class="tabs-description">
              Enter your legal question, and NeXa will help you create a
              detailed memo using case law and statutes.
            </p>
          </section>

          <section class="query-section">
            <article class="case-file mb-4">
              <h5 class="case-file-title mb-2">Ask NeXa</h5>
              <div class="content">
                <div class="searchbar">
                  <form class="entry">
                    <label for="legal-question" class="visually-hidden"
                      >Analyze the enforceability of the non-compete
                      clause</label
                    >
                    <input
                      type="text"
                      id="legal-question"
                      v-model="askQuestion"
                      class="legal-input"
                      placeholder="Analyze the enforceability of the non-compete clause"
                      aria-label="Analyze the enforceability of the non-compete clause"
                    />
                  </form>
                  <div class="char-count">
                    {{ getWordCount(askQuestion) }} / 3000
                  </div>
                </div>
              </div>
            </article>
            <article class="case-file">
              <h5 class="case-file-title mb-2">Findings</h5>
              <div class="content">
                <div class="searchbar">
                  <form class="entry">
                    <label for="legal-question" class="visually-hidden"
                      >Enter Key Findings or Points e.g., Party B failed to
                      deliver on specific dates, causing financial loss.</label
                    >
                    <input
                      type="text"
                      v-model="finding"
                      id="legal-question"
                      class="legal-input"
                      placeholder="Enter Key Findings or Points e.g., Party B failed to deliver on specific dates, causing financial loss."
                      aria-label="Enter Key Findings or Points e.g., Party B failed to deliver on specific dates, causing financial loss."
                    />
                  </form>
                  <div class="char-count">
                    {{ getWordCount(finding) }} / 3000
                  </div>
                </div>
              </div>
            </article>
          </section>

          <section class="upload-container">
            <div class="upload-header">
              <h5 class="case-file-title mb-2">Upload Files</h5>
              <span class="upload-description"
                >Upload any supporting documents to enhance your memo.</span
              >
            </div>

            <UploadFiles
              @get-file="getFile"
              @get-jurisdiction="getJurisdiction"
              not-send
              only-file
            />
          </section>
          <div class="footer-upload">
            <button
              :disabled="!isAllFieldValid"
              :class="{
                'button-generate': isAllFieldValid,
                'button-generate-muted': !isAllFieldValid,
              }"
              @click="generateMemo"
            >
              Generate Insight
            </button>
          </div>
        </div>
      </div>
      <sidebar
        v-if="sidebarCollapsed"
        @toggle-sidebar="toggleSidebar"
      ></sidebar>
    </div>
  </div>
</template>
<script>
import sidebar from "@/Pages/Mode/sidebar.vue";
import UploadFiles from "@/components/input/UploadFiles.vue";
import { getWordCount } from "@/store/utils.js";
import usecase from "@/store/usecase.js";
export default {
  components: {
    UploadFiles,
    sidebar,
  },
  data() {
    return {
      loading: true,
      sidebarCollapsed: false,
      lawbotVisible: false,
      isCountry: false,
      legalQuery: "",
      files: [[], [], []],
      countryMapping: {
        MY: "Malaysia",
        AUS: "Australia",
        CAN: "Canada",
        NZ: "New Zealand",
        UK: "United Kingdom",
        US: "United States",
        SG: "Singapore",
        USA: "USA",
      },
      askQuestion: "",
      finding: "",
      jurisdiction: {},
    };
  },
  computed: {
    filteredCountryImages() {
      return Object.entries(this.countryImages)
        .filter(([countryCode]) => countryCode !== "USA")
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});
    },
    isAllFieldValid() {
      return Object.keys(this.jurisdiction).length > 0 &&
        this.askQuestion.trim() &&
        this.finding.trim()
        ? true
        : false;
    },
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 7000);
  },
  methods: {
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },
    getWordCount(text) {
      return getWordCount(text);
    },
    goBack() {
      this.$router.push({ name: "ExpertMode" });
    },
    toggleCountry() {
      this.isCountry = !this.isCountry;
    },
    showAttachModal() {
      this.$bvModal.show("attachfile");
    },
    hideAttachModal() {
      this.$bvModal.hide("attachfile");
    },

    getFile(file) {
      this.files = file;
    },
    getJurisdiction(jurisdiction) {
      const keys = Object.keys(jurisdiction);
      const key = keys[0];

      this.jurisdiction = {
        [this.countryMapping[key]]: jurisdiction[key],
      };
    },

    async generateMemo() {
      try {
        const formData = new FormData();
        if (this.files[2] && this.files[2].length < 0) {
          this.$toast.error("Please upload at least one file.");
          return;
        }
        this.files[2].forEach((file, index) => {
          formData.append(`question_doc[${index}]`, file.file);
        });

        formData.append("jurisdiction", JSON.stringify(this.jurisdiction));
        formData.append("question", this.askQuestion);
        formData.append("findings", this.finding);
        formData.append("language", "en");
        formData.append("location_id", 1);
        formData.append("type", "build_memo");
        const response = await usecase.CreateUsecase(formData);
        if (response.data) {
          this.$toast.success("Successfully");
          this.$router.push({
            name: "BuildMemoResult",
            params: { id: response.data.data.id },
          });
        }
      } catch (error) {
        console.error(error);
        this.$toast.error("Failed to upload files or generate insights.");
      }
    },
  },
};
</script>

<style scoped>
.footer-upload {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.625rem;
  align-self: stretch;
}

.button-generate {
  border-radius: 4px;
  background: var(--Primary-Blue, #0e4485);
  background-color: var(--Primary-Blue, #0e4485);
  margin-top: 32px;
  gap: 8px;
  overflow: hidden;
  font-size: 16px;
  color: var(--Neutral-White, #fff);
  font-weight: 500;
  padding: 4px 16px;
}

.button-generate-muted {
  border-radius: 4px;
  color: var(--Neutral-Mid-Grey, #d1d2d5);
  background: rgba(14, 68, 133, 0.1);
  margin-top: 32px;
  gap: 8px;
  overflow: hidden;
  font-size: 16px;
  color: var(--Neutral-White, #fff);
  font-weight: 500;
  padding: 4px 16px;
}

.upload-container {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0.5rem;
  width: 100%;
}
.page-content {
  padding: 0;
}
.breadcrumb_header {
  display: flex;
  justify-content: space-between;
  height: 68px;
  background: #fff;
  /* padding: 5px; */
  align-items: center;
}

.breadcrumb {
  display: flex;
  align-items: center;
  margin: auto 0;
  span {
    font-size: 14px;
    color: var(--dark-grey) !important;
  }
}
.open_sidebar {
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center; /* Ensure vertical alignment */
  gap: 8px;
  border-radius: 4px;
  background: var(--primary);
}

.open_sidebar span {
  color: #fff;
  display: flex; /* Ensure icon is aligned vertically */
  align-items: center; /* Align icon vertically within span */
}

.open_sidebar p {
  color: #fff !important;
  margin: 0; /* Remove any default margin to align the text properly */
  line-height: 1; /* Ensure text has the same line height as the icon */
  display: flex;
  align-items: center; /* Align text vertically */
}

.divider {
  display: inline-block;
  margin: 0px 16px;
  width: 1px;
  height: 32px;
  background-color: var(--mid-grey);
}
.back {
  color: var(--primary) !important;
  font-weight: 500;
  padding: 5px;
}
.col-md-10 {
  max-height: 100vh; /* Ensure it doesn’t exceed the viewport height */
}
</style>

<style scoped>
.left-container {
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(170, 171, 175, 0.6);
  display: flex;
  flex-direction: column;
  padding: 22px 24px;
}

@media (max-width: 991px) {
  .left-container {
    padding: 0 20px;
  }
}

.tabs {
  display: flex;
  flex-direction: column;
  font-family: Poppins, sans-serif;
  justify-content: center;
}

.tabs-title {
  color: #0e4485;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}
.tabs-description {
  color: #86888d;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.query-section {
  margin-top: 32px;
  font: 16px/28px Poppins, sans-serif;
}
.case-file {
  display: flex;
  flex-direction: column;
  color: #383a3e;
  font-weight: 500;
  justify-content: center;
}

.overlay-container {
  width: 25%;
  position: fixed;
  top: 8.7vh;
  right: 0;
  z-index: 1000;

  height: 100vh;
}

.case-file-title {
  margin-bottom: 4px;
}

.content {
  color: #d1d2d5;
  font-weight: 400;
}
.searchbar {
  border: 1px solid #f2f3f3;
  border-radius: 8px;
  background-color: #fafbfc;
  display: flex;
  flex-direction: column;
  padding: 22px 24px;
}

.entry {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.legal-input {
  background: none;
  width: 100%;
}

.line {
  border: 1px solid #000;
  height: 28px;
  align-self: flex-start;
}

.char-count {
  align-self: end;
  margin-top: 40px;
}
.upload-file {
  margin-top: 32px;
  flex-direction: column;
  justify-content: flex-start;
}

.upload-section {
  display: flex;
  flex-direction: column;
  color: #383a3e;
  font: 500 16px/28px Poppins, sans-serif;
}
.upload-title {
  margin-bottom: 8px;
}

.upload-description {
  color: #86888d;
  font: 400 12px/24px Poppins, sans-serif;
}

.upload {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}
.upload-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed #d1d2d5;
  border-radius: 8px;
  background-color: #fafbfc;
  min-width: 240px;
  padding: 27px 80px;
}
.upload-figure {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-image {
  width: 32px;
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
}
.upload-caption {
  color: #0e4485;
  font-size: 14px;
  font-weight: 500;
  margin-top: 5px;
}

.upload-choose-file {
  color: #0e4485;
}

.upload-file-format {
  color: #86888d;
  font-size: 12px;
  font-weight: 400;
  margin-top: 5px;
}

.upload-instruction {
  background-color: #fafbfc;
  border-radius: 8px;
  min-width: 240px;
  padding: 58px 70px;
  box-shadow: 0px 0px 4px rgba(242, 243, 243);
  color: #86888d;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
}

.storage-info {
  color: #86888d;
  font: 400 12px/24px Poppins, sans-serif;
  margin-top: 8px;
}

.folder-creation {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
  padding: 8px 16px;
  border-radius: 4px;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.toggle {
  align-items: center;
  display: flex;
}

.toggle-switch {
  width: 40px;
  height: 20px;
  border-radius: 100px;
  background-color: #d1d2d5;
  position: relative;
}
.toggle-checkbox {
  opacity: 0;
  position: absolute;
}

.toggle-checkbox:checked + .toggle-switch {
  background-color: #0e4485;
}

.toggle-checkbox:checked + .toggle-switch::before {
  transform: translateX(20px);
}

.toggle-switch::before {
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 100px;
  background-color: #fff;
  position: absolute;
  transition: transform 0.3s;
  box-shadow: 0px 2px 4px rgba(39, 39, 39, 0.1);
}

.toggle-label {
  font: 400 14px/24px Poppins, sans-serif;
  color: #383a3e;
}
.generate-button {
  align-self: end;
  border-radius: 4px;
  background-color: rgba(14, 68, 133, 0.1);
  color: #d1d2d5;
  padding: 4px 16px;
  margin-top: 32px;
  font: 500 16px Poppins, sans-serif;
}
</style>
<style scoped>
.uploads {
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(227, 229, 235, 0.6);
  margin-top: 24px;
  h5 {
    color: var(--Primary-Blue, #0e4485) !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
}
.sub-title {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 200% */
}
.right-container {
  height: 182px;
  display: flex;
  padding: 0px 53px 0px 99px;
  justify-content: flex-end;
  align-items: center;
  flex: 1 0 0;
  border-radius: 8px;
  background: var(--Neutral-Interface-Grey, #fafbfc);
  box-shadow: 0px 0px 4px 0px #f2f3f3;
  p {
    color: var(--Neutral-Dark-Grey, #86888d) !important;

    /* Small Paragraph */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 200% */
  }
}

.toggle {
  display: flex;
  width: 40px;
}
.switch {
  flex-grow: 1;
  background: var(--Neutral-Mid-Grey, #d1d2d5);
  border-radius: 100px;
  padding: 2px;
  display: flex;
  align-items: center;
}
.button {
  width: 16px;
  height: 16px;
  background: var(--Neutral-White, #fff);
  border-radius: 100px;
  box-shadow: 0 2px 4px rgba(39, 39, 39, 0.1);
}
.swift:hover {
  background: rgba(24, 144, 255, 0.1);
  display: flex;
  border-radius: 4px;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
.upload {
  border-radius: 4px;
  background: rgba(14, 68, 133, 0.1);
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--mid-grey);
}
.upload:hover {
  background: var(--primary);
}
</style>
