import { render, staticRenderFns } from "./legal_memo.vue?vue&type=template&id=4fac7b0f&scoped=true"
import script from "./legal_memo.vue?vue&type=script&lang=js"
export * from "./legal_memo.vue?vue&type=script&lang=js"
import style0 from "./legal_memo.vue?vue&type=style&index=0&id=4fac7b0f&prod&scoped=true&lang=css"
import style1 from "./legal_memo.vue?vue&type=style&index=1&id=4fac7b0f&prod&scoped=true&lang=css"
import style2 from "./legal_memo.vue?vue&type=style&index=2&id=4fac7b0f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fac7b0f",
  null
  
)

export default component.exports